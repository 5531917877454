export enum MP {
    ///// nav Enums/////
    SEND_MESSAGE = "send_message",              // incoming
    MESSAGE = "message",                        // outgoing
    CONNECT = "connect",                        // incoming
    DISCONNECT = "disconnect",                  // incoming
    CHAT_HISTORY = "get_history",               // outgoing
    SERVER_MESSAGE = "server_message",          // outgoing
    ADMIN_LIST = "admin_list",
    ///// game Enums/////
    START_GAME = "start_game",                  // incoming
    INPUT = "input",                            // incoming
    SPEC_INPUTS = "spec_input",                 // outgoing
    PLAYER_LIST = "player_list",                // out
    UID = "send_uid",                           // out
    GAME_STARTED = "game_started",              // outgoing
    GAME_STATE_CHANGE = "game_state_changed",   // outgoig
    INCOMING_GARBAGE = "incoming_garbage",      // out going
    CONFIG = "get_gamecfg",                     // incoming
    RESYNC = "resync",                          // bidirectional
    GAME_ALERT = "game_alert",                  // outgoing
    ///// rOOM ENUMS/////
    CREATE_GAME = "create_game",                // incoming
    GAME_LIST = "get_game_list",                // incoming
    JOIN_GAME = "join_game",                    // iNcomign
    LEAVE_GAME = "leave_game",                  // iNcomign
    GET_USERS = "get_user_list"                 // iNcomign
}

