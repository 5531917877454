import { MP } from "../../shared/communicationEnums";
import { IMessage, IMessageList, IUsersList, ISendMessage, IServerMessage } from "../../shared/message";

export class Chat {
    public socket: SocketIOClient.Socket = io({ transports: ["websocket"], upgrade:false});
    public updateFunc: (message?: any, b?: boolean, room?: number) => void = (c?: any, b?: boolean, r?: number) => { /**/ };
    public updatePing: (ms: number) => void = () => { /**/ };
    public resetChat: (n: number) => void = () => { /**/ };
    public constructor() {
        window.addEventListener("beforeunload", (event) => {
            this.socket.close();
        });

        this.socket.on(MP.MESSAGE, (message: IMessage) => {
            this.updateFunc(message, false, message.room);
        });
        this.socket.on(MP.SERVER_MESSAGE, (message: IServerMessage) => {
            this.updateFunc(message, true);
        });
        this.socket.on(MP.CHAT_HISTORY, (list: IMessageList) => {
            this.resetChat(20);
            for (let i of list.list) {
                this.updateFunc(i);
            }
        });
        this.socket.on(MP.ADMIN_LIST, (users: [string, string][]) => {
            console.log(users);
        });
        this.socket.on(MP.GET_USERS, (users: IUsersList) => {
            let players: string = "";
            let spec: string = "";
            for (let p of users.players) {
                players += p.user.name + ", ";
            }
            for (let s of users.spec) {
                spec += s.name + ", ";
            }
            if (players !== "") {
                this.updateFunc({message: "Playing: " + players.slice(0, -2) }, true, 1);
            }
            if (spec !== "") {
                this.updateFunc({ message: "Watching: " + spec.slice(0, -2) },  true, 1);
            }
        });

        this.socket.on(MP.GAME_ALERT, (s: string) => {
            this.updateFunc({ message: s }, true, 1);
        });

        this.socket.on("pong", (ms: number) => {
            this.updatePing(ms);
        });
    }

    public socketOn(event: string, fn: Function): void {
        this.socket.on(event, fn);
    }

    public sendMsg(msg: string, room: number = 0): void {
        let m: ISendMessage = { type: MP.SEND_MESSAGE, message: msg, room: room };
        this.socket.emit(MP.SEND_MESSAGE, m);
    }
}